import { createPinia } from "pinia";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import useUserStore from "./modules/user";
import useThemeStore from "./modules/theme";
import useCompositionStore from "./modules/composition";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export { useUserStore, useThemeStore, useCompositionStore };
export default pinia;
