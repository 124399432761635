const agreement = {
  path: "/agreement",
  children: [
    {
      path: "/agreement/user",
      name: "agreement-user",
      component: () => import("@/views/agreement/user"),
      meta: {
        title: "用户协议",
      },
    },
    {
      path: "/agreement/privacy",
      name: "agreement-privacy",
      component: () => import("@/views/agreement/privacy"),
      meta: {
        title: "隐私政策",
      },
    },
    {
      path: "/agreement/ai-composition",
      name: "privacy-composition",
      component: () => import("@/views/agreement/composition"),
      meta: {
        title: "AI创作服务协议",
      },
    },
    {
      path: "/agreement/pixiv",
      name: "privacy-pixiv",
      component: () => import("@/views/agreement/pixiv"),
      meta: {
        title: "二次元模型AI创作服务协议",
      },
    },
  ],
};

export default agreement;
