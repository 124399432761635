import { DEFAULT_LAYOUT } from "../base";

const class_a = {
  path: "/",
  component: DEFAULT_LAYOUT,
  redirect: {
    name: "explore",
  },
  children: [
    {
      path: "/explore",
      name: "explore",
      component: () => import("@/views/explore"),
      meta: {
        title: "图韵AI - 让图片更有韵律",
      },
    },
    {
      path: "/explore/:infoId",
      name: "exploreInfo",
      component: () => import("@/views/explore/info.vue"),
      meta: {
        title: "图韵AI - 让图片更有韵律",
      },
    },
    {
      path: "/composition",
      name: "composition",
      component: () => import("@/views/composition"),
      meta: {
        title: "图韵AI - 创作",
      },
    },
    {
      path: "/notice",
      name: "notice",
      component: () => import("@/views/notice"),
      meta: {
        title: "图韵AI - 通知",
      },
    },
    {
      path: "/user",
      name: "user",
      component: () => import("@/views/user"),
      meta: {
        title: "我",
      },
    },
    {
      path: "/search",
      name: "search",
      component: () => import("@/views/explore/search.vue"),
      meta: {
        title: "图韵AI - 图韵AI搜索",
      },
    },
    {
      path: "/incantation",
      name: "incantation",
      component: () => import("@/views/composition/incantation"),
      meta: {
        title: "咒语生成器",
      },
    },
  ],
};

export default class_a;
