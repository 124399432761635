import { defineStore } from "pinia";

export const useThemeStore = defineStore("theme", {
  state: () => ({
    dark: false,
    navLight: "/explore",
  }),
  getters: {
    themeInfo: (state) => {
      return {
        ...state,
      };
    },
  },
  actions: {
    setNavLight(value) {
      console.log(value)
      this.$patch({
        navLight: value,
      });
    },
    setDark(value) {
      this.$patch({
        dark: value,
      });
      value
        ? document.body.setAttribute("arco-theme", "dark")
        : document.body.removeAttribute("arco-theme");
    },
  },
  persist: true,
});

export default useThemeStore;
