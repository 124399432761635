import { createRouter, createWebHistory } from "vue-router";
import { appRoutes } from "./routes";
import { REDIRECT_MAIN, NOT_FOUND_ROUTE } from "./routes/base";
import createRouteGuard from "./guard";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({ showSpinner: false });
const routes = [
  {
    path: "/",
    redirect: "explore",
  },
  ...appRoutes,
  REDIRECT_MAIN,
  NOT_FOUND_ROUTE,
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

createRouteGuard(router);

export default router;
