/**
 * 登录及个人信息接口封装。
 */
import axios from "axios";
import useSign from "@/utils/sign";
const { encode } = useSign();

export function postCode(params) {
  params = `s=${encode(params)}`;
  return axios.post("/sms/front/sendCode", params);
}

export function postLogin(params) {
  params = `s=${encode(params)}`;
  return axios.post("/sms/front/checkCode", params);
}

export function postUserInfo(params) {
  params = `s=${encode(params)}`;
  return axios.post("/user/info", params);
}