/**
 * axios请求拦截器
 */
import axios from "axios";
// import { useUserStore } from "@/store";
import { getToken } from "@/utils/auth";
import { Message } from "@arco-design/web-vue";
//import useSign from "@/utils/sign";
//const { decode } = useSign();
axios.defaults.baseURL =
  process.env.NODE_ENV == "development" ? "/api" : "https://www.tuyun.art/api";

axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `TYAI ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 200) {
      Message.error({
        content: res.message || "请求状态错误",
      });

      return Promise.reject(new Error(res.message || "请求状态错误"));
    }
    // const result = {
    //   code: res.code,
    //   data: res.data ? decode(res.data) : null,
    //   message: res.message,
    //   success: res.success,
    // };

    // if (res.query) {
    //   result.query = res.query;
    // }
    // return result;
    return res;
  },
  (error) => {
    Message.error({
      content: error.msg || "请求发生错误",
    });
    return Promise.reject(error);
  }
);
