import { defineStore } from "pinia";

export const useCompositionStore = defineStore("composition", {
  state: () => ({
    again: null,
    release: null,
    incantation: [],
  }),
  getters: {
    compositionInfo: (state) => {
      return {
        ...state,
      };
    },
  },
  actions: {
    setAgain(value) {
      this.$patch({
        again: value,
      });
    },
    setRelease(value) {
      this.$patch({
        release: value,
      });
    },
    setIncantation(value) {
      this.$patch({
        incantation: value,
      });
    },
  },
});

export default useCompositionStore;
