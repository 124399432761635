// 参数加密和返回结果解密
import CryptoJS from "crypto-js";
import md5 from "js-md5";

export default function useSign() {
  const aesKey = "tuyunKjAIstabled";
  const encode = (params) => {
    params.time = new Date().getTime();
    params.sign = md5(`${JSON.stringify(params)}+${aesKey}`);
    console.log(params)
    const paramsStr = JSON.stringify(params);

    const en = CryptoJS.AES.encrypt(
      paramsStr,
      CryptoJS.enc.Utf8.parse(aesKey),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();

    return en;
  };

  const decode = (result) => {
    const de = CryptoJS.AES.decrypt(result, CryptoJS.enc.Utf8.parse(aesKey), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    if (de) {
      console.log(JSON.parse(de))
      return JSON.parse(de);
    }
  };

  return {
    encode,
    decode,
  };
}
