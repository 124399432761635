import { useUserStore } from "@/store";
import { isLogin } from "@/utils/auth";

export default function setupUserLoginInfoGuard(router) {
  router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();
    if (isLogin()) {
      if (userStore.userId) {
        next();
      } else {
        try {
          await userStore.info();
          next();
        } catch (error) {
          await userStore.logout();
          next({
            name: "explore",
            query: {
              redirect: to.name,
              ...to.query,
            },
          });
        }
      }
    } else {
      if (to.name == "explore") {
        next();
        return;
      } else if (to.name == "notice" || to.name == "composition" || to.name == "user") {
        next({
          name: "explore",
          query: {
            redirect: to.name,
            ...to.query,
          },
        });
      }else{
        next();
        return;
      }
    }
  });
}
