import { defineStore } from "pinia";
import { getToken, setToken, clearToken } from "@/utils/auth";
import { postUserInfo, postLogin } from "@/api/login";
export const useUserStore = defineStore("user", {
  state: () => ({
    userId: "",
    nickname: "",
    sex: "",
    intro: "",
    userLogo: null,
    userNumber: "",
    ipHomePlace: "",
  }),

  getters: {
    userInfo(state) {
      return {
        ...state,
      };
    },
  },

  actions: {
    // 设置用户信息
    setInfo(partial) {
      this.$patch(partial);
    },

    // 重置用户信息
    resetInfo() {
      this.$reset();
    },

    // 请求用户信息
    async info() {
      const token = getToken("TYAI-token");
      const params = {
        userId: token,
      };
      const res = await postUserInfo(params);
      // 利用token请求接口获取信息
      this.setInfo(res.data);
    },

    // 登录
    async login(loginForm) {
      try {
        const res = await postLogin(loginForm);
        // 获取用户登录标识
        setToken("TYAI-token", res.data.userId);
        window.location.reload();
      } catch (err) {
        clearToken("TYAI-token");
        throw err;
      }
    },

    // 登出
    async logout() {
      this.resetInfo();
      clearToken("TYAI-token");
      window.location.reload();
    },
  },
});

export default useUserStore;
